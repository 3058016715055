.ballot {margin-bottom: 10px;}
.ballot-body {border: 2px solid white !important;}
.ballot-error {border: 2px solid red !important; background-color: rgb(244,204,205);}
.ballot-office-name {font-size: 1.65rem; font-weight: bold; text-align: center;}
.ballot-blot {height: 30px; width: 30px; border-radius: 15px; border: 3px solid black; margin-top: 15px; cursor: pointer;}
.ballot-blot-selected {background-color: black;}
.ballot-candidate {font-size: 1.30rem; }
.ballot-candidate-name {padding: 10px 0 10px 10px; font-size: 1.30rem; }
.ballot-vote-for-number {text-align: center; font-size: 1rem;}
.ballot-cast-vote-button {margin-top: 30px; text-align: center;}

